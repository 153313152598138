body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-header{
  background-color: #A020F0!important;
}

.residentAdvisor{
  width:100%;
  height:2000px;
}

.emailForm{
  padding:0px 50px 50px 50px;
}

.emailText{
  color: #FFFFFF;
  margin-bottom:20px;
}
